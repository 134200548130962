import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/256aa59e/src/components/default.mdx.js";
import IndexLayout from "../components/index.mdx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = IndexLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Welcome`}</h1>
    <p>{`This is a podcast/blog all about wrestling past and present. Tune in each week to hear amazing
stories from pros of the past, and tips for success from wrestlings newest rising stars!`}</p>
    <Video vid="pYRsgs1WQ14" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      